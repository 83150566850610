import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["darkmode"];

  connect() {
    // let dropdown = document.getElementsByClassName('dropdown');
    let darkmode = this.linkTarget;

    if(window.location.search.match('dark=1')){
      localStorage.theme = 'dark';
    }

    if('theme' in localStorage){
      if(localStorage.theme === 'dark'){
        this._enableDarkMode();
      } else {
        this._enableLightMode();
      }
    } else {
      // this._setDarkMode();
      this._enableLightMode();
    }

    this._setIcon();
  }

  _setDarkMode() {
    if (localStorage.theme === 'dark') {//|| (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
      this._enableDarkMode();
    } else {
      this._enableLightMode();
    }
  }

  _setCookie(value){
    var myDate = new Date();
    myDate.setMonth(myDate.getMonth() + 12);
    document.cookie = "darkmode=" + value + ";expires=" + myDate + ";path=/;samesite;secure";
  }

  _enableDarkMode() {
    document.documentElement.classList.add('dark');
    this._setCookie(true);
  }

  _enableLightMode() {
    document.documentElement.classList.remove('dark');
    this._setCookie(false);
  }

  _enableIcon(icon){
    document.getElementsByClassName('js-icon-light')[0].classList.add('hidden');
    document.getElementsByClassName('js-icon-dark')[0].classList.add('hidden');
    // document.getElementsByClassName('js-icon-system')[0].classList.add('hidden');

    document.getElementsByClassName('js-icon-' + icon)[0].classList.remove('hidden');
  }

  _setIcon(){
    if (localStorage.theme === 'dark') {
      this._enableIcon('dark');
    } else if(localStorage.theme === 'light') {
      this._enableIcon('light');
    } else {
      // this._enableIcon('system');
      this._enableIcon('light');
    }
  }

  toggle() {
    if (localStorage.theme === 'dark') {
      localStorage.theme = 'light';
      this._enableLightMode();
    } else if (localStorage.theme === 'light') {
      localStorage.theme = 'dark';
      this._enableDarkMode();
    } else if (!('theme' in localStorage)) {
      localStorage.theme = 'dark';
      this._enableDarkMode();
    }

    // On page load or when changing themes, best to add inline in `head` to avoid FOUC
    this._setIcon();
    this._setDarkMode();
  }
}