// IMPORTANT! This is used in both general search and services search

document.addEventListener("DOMContentLoaded", (e) => {
  search_input = document.getElementById('q');

  if(!!search_input){
    search_input.addEventListener('keyup', function(){
      search();
    });

    document.getElementById('q').addEventListener('blur', function(e){
      setTimeout(function(){
        let search_results = document.getElementById('search-results');
        if(search_results){
          search_results.innerHTML = '';
        }
      }, 500)
    });
  }
});

let searching;

function search(){
  clearTimeout(searching);

  searching = setTimeout(function(){

    search_form = document.getElementById('search');

    if(search_form.requestSubmit){
      search_form.requestSubmit();
    }
  }, 500);
}
