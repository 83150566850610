import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ["menu"];

    connect() {
        // let dropdown = document.getElementsByClassName('dropdown');
        let menu = this.menuTarget;

        document.addEventListener('click', function (event) {
            let dropdownButton = event.target.closest(".dropdown");
            let menu = event.target.closest(".menu");

            if (dropdownButton == null) {
                document.querySelectorAll('.menu').forEach(menuDropdown => {
                    if (!menuDropdown.classList.contains('hidden')) {
                        menuDropdown.classList.add('hidden');
                    }
                });
            } else if (dropdownButton != event.target.parentNode && dropdownButton.parentNode != event.target.parentNode) {
                if (!menu.classList.contains('hidden')) {
                    menu.classList.add('hidden');
                }
            }
        });
    }

    toggle() {
        if (this.menuTarget.classList.contains('hidden')) {
            this.menuTarget.classList.remove('hidden');
        } else {
            this.menuTarget.classList.add('hidden');
        }
    }
}