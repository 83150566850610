import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ["link", "copy"];

    connect() {
        // let dropdown = document.getElementsByClassName('dropdown');
        let publicLink = this.linkTarget;
        let copyButton = this.linkTarget;

    }

    copy() {
      var copyText = this.linkTarget;

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */

      navigator.clipboard.writeText(copyText.value).then(function() {
      }, function(err) {
        console.error('Async: Could not copy text: ', err);
      });

      let pIcon = document.getElementById('1st-icon');
      let sIcon = document.getElementById('2nd-icon');

      pIcon.classList.add('hidden');
      sIcon.classList.remove('hidden');

      setTimeout(function(){
        pIcon.classList.remove('hidden');
        sIcon.classList.add('hidden');
      }, 700);
    }
}